













import { Component, PropSync, Vue } from "vue-property-decorator";
import { Action } from "@/models/entities/action.interface";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import UtilsModule from "@/store/modules/utils.module";
const utilsModule = getModule(UtilsModule, store);

@Component
export default class CosmopolStatusSelect extends Vue {
  @PropSync("selected", { required: false, default: null, type: Number })
  private _selected: number;

  get status(): Action[] {
    return utilsModule.contextActions;
  }
}
